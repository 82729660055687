<template>
  <div class="home">
    <section id="first" class="pt-4 relative">
      <div class="logo ml-10">
        <router-link to="/"
          ><img class="logo-image" src="../assets/logo-farmaenlace.svg"
        /></router-link>
      </div>
      <nav class="md:block hidden">
        <ul>
          <li>
            <router-link to="/">Inicio</router-link>
          </li>
          <li>
            <router-link to="/avances"> Revisa tu avance</router-link>
          </li>
          <!-- <li>
            <a href="legales.html" target="_blank">Términos y condiciones</a>
          </li> -->
        </ul>
      </nav>
      <div class="decorator1 absolute top-0 right-0 md:block hidden">
        <!-- <img class="w-full" src="../assets/decorator1.png" /> -->
      </div>
      <div class="inner-container px-4 pt-4 pb-2 relative z-50 md:mt-6 mx-auto">
        <div class="grid md:grid-cols-2 gap-12 md:px-20 px-4 md:items-center">
          <div class="logo md:mt-4">
            <img src="../assets/landing-dependientes_duendes.png" />
          </div>
          <div>
            <div class="text-white veneer text-2xl">Activa tu cuenta</div>
            <div class="text-white">Ingresa tu password y activa tu cuenta</div>
            <div>
              <input
                class="block w-10/12 my-4 mx-auto"
                type="text"
                name="password"
                v-model="password"
                placeholder="Contraseña:"
              />
              <input
                class="block w-10/12 my-4 mx-auto"
                type="text"
                name="password2"
                v-model="password2"
                placeholder="Confirma tu contraseña:"
              />
              <div>
                <p
                  class="text-white roboto my-3"
                  v-if="password2 && !$v.password2.sameAsPassword"
                >
                  Ambas contraseñas deben ser iguales
                </p>
              </div>
              <a @click="activar" class="mr-6"
                ><img class="mx-auto" src="../assets/ingresar-btn.png"
              /></a>
            </div>

            <!-- <div class="mt-32 text-white text-5xl">
              Promoción válida desde el 15 de Agosto del 2021
            </div>
            <div class="text-white text-2xl">
              Guarda el empaque con tu código
            </div> -->
            <!-- <div class="text-white mt-8">
              Además ya estás participando en el sorteo de 5 giftcards de $1000
            </div> -->
            <!-- <div class="text-white text-2xl mt-6">
              RECUERDA QUE INGRESANDO MÁS CÓDIGOS TIENES MÁS OPORTUNIDAD DE
              GANAR
            </div> -->
          </div>
        </div>
        <div class="mt-12 text-center text-white">
          <a href="legales.html" target="_blank">
            Aplican términos y condiciones. Promoción válida de 15 de octubre al
            31 de diciembre.</a
          >
        </div>
      </div>
      <div class="decorator2 absolute right-0 w-1/6 z-40 md:block hidden">
        <!-- <img src="../assets/decorator2.png" /> -->
      </div>
    </section>
    <!-- inicio pasos para participar -->
    <section id="second" class="py-8">
      <div class="inner-container md:px-20 px-4 mx-auto">
        <div>
          <img class="mx-auto" src="../assets/como-revisar-avance.png" />
        </div>
        <div
          class="
            grid
            md:grid-cols-3
            grid-cols-1
            mx-auto
            justify-center
            barra-participa
            my-10
          "
        >
          <div class="paso-1">
            <div class="mx-auto numero veneer">1</div>
            <div class="uppercase green veneer titulo-paso">
              activa tu cuenta desde el mail que te enviamos
            </div>
            <div class="green notice-paso mt-4">
              Si no recibiste el mail, comunícate con nostros a
              info@farmaenlace.com
            </div>
          </div>
          <div class="paso-2">
            <div class="mx-auto numero veneer">2</div>
            <div class="uppercase green veneer titulo-paso">inicia sesión</div>
            <div class="green notice-paso mt-4">
              Ingresa tu correo y contraseña creada
            </div>
          </div>
          <div class="paso-3">
            <div class="mx-auto numero veneer">3</div>
            <div class="uppercase green veneer titulo-paso">
              revisa tu avance con los termómetros
            </div>
            <div class="green notice-paso mt-4">
              Cada viernes actualizamos tus metas para que las puedas revisar
            </div>
          </div>
        </div>
        <div class="mt-12 text-center text-white">
          <a href="legales.html" target="_blank">
            *Promoción válida a nivel nacional para mayores de 12 años
            residentes en Ecuador, aplican términos y condiciones en la página
            web.</a
          >
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import Vue from "vue";
import axios from "axios";
import Vuelidate from "vuelidate";
Vue.use(Vuelidate);
import { required, minLength, sameAs } from "vuelidate/lib/validators";
export default {
  name: "Home",
  data() {
    return {
      email: "",
      password2: "",
      password: "",
      incorrecto: false,
    };
  },
  components: {
    // HelloWorld
  },
  validations: {
    password: {
      required,
      minLength: minLength(3),
    },
    password2: {
      required,
      sameAsPassword: sameAs("password"),
    },
  },
  methods: {
    activar() {
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.error_formulario = true;
      } else {
        const URL = "https://api.navidadfarmaenlace.com/api/user/activate";
        var headers = {
          "content-type": " application/json",
        };
        let data = { email: this.email, password: this.password };

        axios.post(URL, data, headers).then((response) => {
          console.log(response.data);
          if (response.data.error != " ") {
            this.$store.state.autenticado = true;
            this.$store.state.user_id = response.data.message;
            this.gotoLogin();
          } else {
            this.error_usuario = true;
          }
        });
      }
    },

    gotoLogin() {
      this.$router.push("/");
    },
  },
  mounted() {
    this.email = this.$route.query.email;
    console.log(this.email);
  },
};
</script>
<style scoped>
#first {
  background: url("../assets/fondo.jpg");
  overflow: hidden;
}
#second {
  background: #277a3d;
  position: relative;
}
#third {
  background: #dc6a06;
}
.home {
}
.logo-image {
  max-width: 180px;
}
.inner-container {
  max-width: 1240px;
}
.decorator1 {
  max-width: 200px;
}
.decorator2 {
  bottom: -90px;
}
.decorator3 {
  top: -90px;
  max-width: 200px;
}
.premios img {
  width: 75%;
  margin: 0 auto;
}
.titulos {
  color: white !important;
}
.numero {
  background: red;
  width: 40px;
  height: 40px;
  line-height: 41px;
  color: white;
  font-size: 26px;
  border-radius: 50%;
  font-weight: bold;
  position: relative;
  top: -35px;
}
.titulo-paso {
  font-size: 34px;
  line-height: 29px;
  padding: 0 40px;
}
.notice-paso {
  padding: 0 20px;
}
.barra-participa {
  position: relative;
  background: white;
  border-radius: 30px;
  padding: 20px 40px;
}
.green {
  color: #008126;
}
.paso-1 {
  background: url("../assets/inbox-icon.png");
  background-repeat: no-repeat;
  background-position: 0 20px;
}
.paso-2 {
  background: url("../assets/sesion-icon.png");
  background-repeat: no-repeat;
  background-position: 0 20px;
}
.paso-3 {
  background: url("../assets/termometro-icon.png");
  background-repeat: no-repeat;
  background-position: 0 20px;
}
nav {
  position: absolute;
  top: 53px;
  right: 100px;
  background: #d60707;
  border-radius: 15px;
  z-index: 100;
  padding: 5px 20px;
}
nav ul > li {
  display: inline-block;
  margin: 0 15px;
  color: white;
  font-weight: bold;
  font-size: 13px;
  cursor: pointer;
}
a {
  cursor: pointer;
}
.listado-pasos {
  max-width: 700px;
  margin: 20px auto;
}
.listado-pasos li {
  margin: 20px 0;
}
.listado-icono {
  max-height: 60px;
  margin-right: 20px;
}
.listado-numero {
  max-height: 80px;
}
h2 {
  color: #231f20;
  font-size: 34px;
  font-weight: bold;
}
.pasos-participar h3 {
  margin-top: 8px;
  text-align: left;
  color: #231f20;
  font-size: 30px;
  font-weight: bold;
  line-height: 23px;
}
.pasos-participar span {
  display: block;
  text-align: left;
  color: white;
  font-size: 20px;
  font-weight: 100;
}
#third h3 {
  color: #231f20;
  font-size: 22px;
}
.jackpot-premio {
  font-weight: 500;
  font-size: 14px;
  display: block;
  line-height: 15px;
  margin-top: 10px;
}
.premios-jackpot {
  flex-wrap: wrap;
  width: 1080px;
  margin: 0 auto;
}
.premios-jackpot > div {
  width: 150px;
  margin: 15px 5px;
}

.premios-jackpot img {
  width: 85%;
}
input {
  border-radius: 15px;
  padding: 10px 15px;
}
input::placeholder {
  font-family: "Roboto", sans-serif;
}
@media only screen and (max-width: 767px) {
  .paso-1 {
    background: none;
  }
  .paso-2 {
    background: none;
  }
  .paso-3 {
    background: none;
  }
  .numero[data-v-fae5bece] {
    top: -2px;
  }
  h2 {
    font-size: 24px;
    font-weight: bold;
  }
  .pasos-participar h3 {
    font-size: 17px;
  }
  .pasos-participar span {
    font-size: 14px;
  }
  .listado-icono {
    max-height: 35px;
    margin-right: 10px;
  }
  .listado-numero {
    max-height: 50px;
  }
}
</style>
